exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-wp-career-js": () => import("./../../../src/templates/WpCareer.js" /* webpackChunkName: "component---src-templates-wp-career-js" */),
  "component---src-templates-wp-careers-archive-js": () => import("./../../../src/templates/WpCareersArchive.js" /* webpackChunkName: "component---src-templates-wp-careers-archive-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-post-archive-js": () => import("./../../../src/templates/WpPostArchive.js" /* webpackChunkName: "component---src-templates-wp-post-archive-js" */),
  "component---src-templates-wp-post-category-js": () => import("./../../../src/templates/WpPostCategory.js" /* webpackChunkName: "component---src-templates-wp-post-category-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WpPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */),
  "component---src-templates-wp-press-archive-js": () => import("./../../../src/templates/WpPressArchive.js" /* webpackChunkName: "component---src-templates-wp-press-archive-js" */),
  "component---src-templates-wp-press-js": () => import("./../../../src/templates/WpPress.js" /* webpackChunkName: "component---src-templates-wp-press-js" */),
  "slice---src-components-footer-index-js": () => import("./../../../src/components/footer/index.js" /* webpackChunkName: "slice---src-components-footer-index-js" */),
  "slice---src-components-header-index-js": () => import("./../../../src/components/header/index.js" /* webpackChunkName: "slice---src-components-header-index-js" */),
  "slice---src-components-press-index-js": () => import("./../../../src/components/press/index.js" /* webpackChunkName: "slice---src-components-press-index-js" */)
}

